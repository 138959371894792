import { Component }     from '@angular/core';


@Component({
    templateUrl:'./about.html',
    styleUrls: ['./style/homeStyle.css'],
    
})
export class AboutComponent { 
    // Define some properties at the class level.
    
}
