import { Component } from '@angular/core';


@Component({
    selector: 'app-root',
    templateUrl: './main.html',
    styleUrls: ['./style/homeStyle.css']

})
export class AppComponent { 
  celsiusSymbol = '\u2103'
}
