import { Component }                    from '@angular/core';
import { ActivatedRoute, Params }       from '@angular/router';
import { WeatherService}                from './app.weatherservice';

@Component({
templateUrl: './detail.html',
styleUrls: ['./style/homeStyle.css'],
 providers: [WeatherService]      
})
export class ForecastDetail {
     city:string;
     forecastArray:  Array<any>;
     _weatherService:WeatherService;
     Fahrenheit = false;
     Celsius  = true;
     convert = true;
     tempSymbol = '\u2103';

    // The constructor sets up the class.
    constructor(private route: ActivatedRoute, weatherService:WeatherService) {
        this._weatherService = weatherService;
    }

    // ngOnInit() is called after the class object is set up (constructed).    
    ngOnInit() {
        // At this point, the class is able to handle the parameters passed to it.
        this.route.params.forEach((params: Params) => {
            this.city = params['city'];
            console.log("This city paramter is: " + this.city);
            this.getWeather(this.city)
        });
    }

    convertTemperature(){
        this.convert = !this.convert;
        
        if(this.convert == true) {
            this.tempSymbol = '\u2103';
        }else {
            this.tempSymbol = '\u2109';
        }
     }

     getWeather(city) {
         console.log("Inside getWeather(). Parameter = " + city);
        this._weatherService.getWeather(city)
            // Subscribe to changes in the observable object
            // that is returned by getRemoteData.
            .subscribe(
        
            // 1. Handle successful data response.
            data => {
                this.forecastArray = data["query"].results.channel.item.forecast;             
            },
            // 2. Handle error.
            error => {
                alert(error)
            });
    }    
}
