import { Component }     from '@angular/core';
import { WeatherService} from './app.weatherservice';

@Component({
    templateUrl:'./home.html',
    styleUrls: ['./style/homeStyle.css'],
    providers: [WeatherService]
})
export class HomeComponent { 
    // Define some properties at the class level.
    cityArray = ['Vancouver', 'Abbotsford','Taichung', 'New York'];
    forecastArray: Array<any> = new Array();
    _weatherService: WeatherService;
    searchText = "";
    tempSymbol = '\u2103';
    Celsius  = true;
    Fahrenheit = false;
    convert = true;
    date = Date.now();

    // Since we are using a provider above we can receive 
    // an instance of the Weather Service through an instructor.
    constructor(weatherService: WeatherService) {
        // Store reference to weather service. 'this' points to the class level
        // declaration.
        this._weatherService = weatherService;

        // Loop through cities and call weather service for each.
        for(var i=0; i<this.cityArray.length; i++) {
            let forecastObject = this.getWeather(this.cityArray[i]);   
        }
    }
     
     searchCity(city) {
        
        this.cityArray = [this.searchText];
        this.forecastArray = [];

        for(let i = 0; i < this.cityArray.length; i++ ){
            let forecastObject = this.getWeather(this.cityArray[i]);
        }

     }
        
     convertTemperature(){
        this.convert = !this.convert;
        
        if(this.convert == true) {
            this.tempSymbol = '\u2103';
        }else {
            this.tempSymbol = '\u2109';
        }
     }

     getWeather(city) {
        this._weatherService.getWeather(city)
            
            // Subscribe to changes in the observable object
            // that is returned by getRemoteData.
            .subscribe(
                
            // 1. Handle successful data response.
            data => {
                console.log(city);

                // Get current temperature. 
                let currentCondition = data["query"].results.channel.item.condition;

                // You can show JSON objects in the console if you convert them to strings.
                //  \n means new line. 
                console.log("***\n Current Condition:\n" + JSON.stringify(currentCondition));

                // Show all content.
                console.log("***\n All data: \n" + JSON.stringify(data["query"]));
                
                // repair the bug that when refresh the page, the city tempurature not correspondence

                let cityObject = {
                    city:city,
                    info:currentCondition
                }
                // Store data in the array the application can use it.
                this.forecastArray.push(cityObject);               
            },
            // 2. Handle error.
            error => {
                alert(error)
            });
    }
}
